<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Monitor from "@/services/Monitor";
import Auth from "@/services/Auth";
import Swal from "sweetalert2";


export default {
  components: { Layout, PageHeader },
  data() {
    return {
      showLoader: false,
      error: null,
      tableData: [],
      title: "Api Access",
      currentPage: 1,
      perPage: 10,
      items: [] ,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "version",
      isUpdating: false,
      sortDesc: true,
      isTableLoading: false,
      roles : [],
      fields: [
        {
          key: "role",
          label: "Role",
          visible: false,
        },
        {
          key: "access_id",
          label: "Access ID",
          visible: true,
        },
        {
          key: "method",
          label: "Method",
          visible: true,
        },
        {
          key: "status",
          label: "Status",
          visible: true,
          slot: 'status'
        },

      ],
      filterInput: {
        role: '',
      },
      totalRows: 0,
      showModal: false,
      selectedRow: null,
      statusOptions: [
        { value: 0, text: "0" },
        { value: 1, text: "1" }
      ]


    };
  },
  async mounted() {
    try {
      await this.getRbacRoleAccess();
      await this.getRbacRoles();
    } catch (error) {
      console.error('Error during component initialization:', error);
    }
  },
  methods: {

    async getRbacRoles(){
      this.showLoader = true;
      try {
        const response = await Auth.getRbacRoles();
        this.roles = response.data.data ;
      } catch (error) {
        this.error = error.response.data.error ? error.response.data.error : "";

      } finally {
        this.showLoader = false;
      }
    } ,
    async getRbacRoleAccess(roleId) {
      this.showLoader = true;
      try {
        this.isTableLoading = true;
        const response = await Auth.getRbacRoleAccess(roleId);
        this.tableData = response.data.data;
        this.totalRows = this.tableData.length;
      } catch (error) {
        this.error = error.response.data.error ? error.response.data.error : "";
        this.tableData = [];
        this.totalRows = 0;
      } finally {
        this.showLoader = false;
        this.isTableLoading = false;
      }
    },
    getFilters(){
      var filter_string = '';
      if(this.filterInput.role) {
        filter_string += (this.filterInput.role) ? '&serie_id=' + this.filterInput.role : "";
      }

      return filter_string = filter_string.substring(1);
    },
    async handleStatusChange(item) {
      try {

        const updatedStatus = item.status ? 1 : 0;
        const dataToUpdate = {
          ...item,
          status: updatedStatus,
        };

        const response = await Auth.updateRbacRoleAccess(dataToUpdate);
        if (response.data) {
          // Find the index of the item to update
          const index = this.tableData.findIndex(i => i.access_id === item.access_id);
          if (index !== -1) {
            // Update the item in tableData and set showIcon based on status
            this.$set(this.tableData, index, {
              ...item,
              status: updatedStatus,
              source: 'tenant'
            });
          }

        } else {
          const error = response.data.error ? response.data.error : 'Failed';
          Swal.fire("Fail!", error, "warning");
        }
      } catch (error) {
        Swal.fire("Fail!", error.message, "warning");
      } finally {
        this.showLoader = false;
      }
    },
    async handleRoleChange(roleId = '' ) {
      this.getRbacRoleAccess(roleId);
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  middleware: "authentication",
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="!showLoader">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12  col-md-4 mx-auto">
                <b-form-group label="Select Role" label-for="role" class="mb-3 text-center">
                  <select v-model="filterInput.role" class="form-select" id="role" @change="handleRoleChange(filterInput.role)">
                    <option value="">Select All</option>
                    <option v-for="role in roles" :key="role.id" :value="role.id">{{role.name}}</option>
                  </select>
                </b-form-group>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="col-sm-12 col-md-12">
              <div class="dataTables_filter text-md-center">
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                      style="width: 300px;"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table
                  :busy="isTableLoading"
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  show-empty
                  empty-text="No Data Found"
                  @filtered="onFiltered"
              >
                <template #cell(status)="row">
                  <div class="form-check form-switch form-switch-lg" dir="ltr">
                    <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'customSwitchsizelg' + row.item.access_id"
                        v-model="row.item.status"
                        @change="handleStatusChange(row.item)"
                    />
                  </div>
                </template>
                <template #cell(method)="row">
                  <span>{{ row.item.method }}</span>
                  <span v-if="row.item.source === 'tenant'" class="text-primary ms-3 font-size-20" title="Status from Tenant">
                    <i class="uil uil-info-circle"></i>
                  </span>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="card-body d-flex align-items-center justify-content-center">
        <b-spinner large></b-spinner>
      </div>
    </div>
  </Layout>
</template>
